import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";

export default function CookiePolicy() {
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>COOKIE POLICY</h3>
              <p>
                This cookie policy explains how our website uses cookies to enhance
                your user experience and comply with relevant laws and regulations. By
                using our website, you consent to our use of cookies in accordance
                with this policy.
              </p>
              <h3>1. What are cookies?</h3>
              <p>
                Cookies are small text files that are stored on your device (computer,
                tablet, smartphone, etc.) when you visit a website. They are commonly
                used to enable websites to work more efficiently and to provide
                information to website owners.
              </p>
              <h3>2. How we use cookies</h3>
              <p>
                We use cookies to improve your user experience, personalize your
                preferences, and analyze website traffic. We may also use cookies to
                display personalized advertising or content based on your browsing
                history.
              </p>
              <p>The cookies we use fall into three categories:</p>
              <ol>
                <li>
                  <strong>Essential cookies:</strong> These cookies are necessary for
                  the website to function properly. They enable basic website
                  features, such as navigating pages and accessing secure areas of the
                  website. Without these cookies, the website cannot function
                  properly.
                </li>
                <li>
                  <strong>Performance cookies:</strong> These cookies collect
                  information about how visitors use the website, such as which pages
                  are most popular or how long visitors spend on each page. This
                  information helps us improve the website and make it more
                  user-friendly.
                </li>
                <li>
                  <strong>Advertising cookies:</strong> These cookies are used to
                  display personalized advertising or content based on your browsing
                  history. They may also be used to limit the number of times you see
                  a particular ad or measure the effectiveness of a marketing
                  campaign.
                </li>
              </ol>
              <h3>3. Managing cookies</h3>
              <p>
                You can manage your cookie preferences through your browser settings.
                You can choose to accept or reject cookies or to be notified when a
                cookie is placed on your device. Please note that rejecting cookies
                may affect your ability to use certain features of our website.
              </p>
              <h3>4. International standards and laws</h3>
              <p>
                Our use of cookies complies with relevant international standards and
                laws, including the General Data Protection Regulation (GDPR) in the
                European Union and the California Consumer Privacy Act (CCPA) in the
                United States. We respect your right to privacy and are committed to
                protecting your personal data.
              </p>
              <h3>5. Changes to this policy</h3>
              <p>
                We may update this cookie policy from time to time to reflect changes
                in our use of cookies or relevant laws and regulations. We encourage
                you to check this policy periodically for any updates.
              </p>
              <h3>6. Contact us</h3>
              <p>
                If you have any questions or concerns about our use of cookies or this
                cookie policy, please contact us at <br /><br />
                99GENS LLC<br />
                651 N. BROAD ST., SUITE 201<br />
                MIDDLETOWN DE 19709<br />
                or, by electronic mail:
                <a href="mailto:legal@99gens.com">{' legal@99gens.com'}</a>.
              </p>
              <h3>7. Conclusion</h3>
              <p>
                Thank you for reading our cookie policy. By using our website, you
                consent to our use of cookies in accordance with this policy. We are
                committed to protecting your privacy and providing you with the best
                possible user experience.
              </p>
              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
